import axios from 'axios'

// axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 10000*60,
})

// let reqLoading;
// request拦截器
service.interceptors.request.use(config => {
  if (config.data instanceof FormData) {
    config.headers['Content-Type'] = null;
  }
  //get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?';
    for (const propName of Object.keys(config.params)) {
      const value = config.params[propName];
      var part = encodeURIComponent(propName) + "=";
      if (value !== null && typeof (value) !== "undefined") {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            let params = propName + '.' + key;
            if (propName === "params") {
              params = propName + '[' + key + ']';
            }
            if(value[key]){
              let subPart = encodeURIComponent(params) + "=";
              url += subPart + encodeURIComponent(value[key]) + "&";
            }
          }
        } else {
          url += part + encodeURIComponent(value) + "&";
        }
      }
    }

    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  return config
}, error => {if (reqLoading) {
    // reqLoading.close();
  }
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // if (reqLoading) {
    //   reqLoading.close();
    // }
      return res;
  },
  error => {
    // if (reqLoading) {
    //   reqLoading.close();
    // }
    console.log('err:' + error)
    return Promise.reject(error)
  }
)
export default service;

export function requestGet(url, params) {
  return service({url: url, method: "get", params: params});
}

export function requestPost(url, params) {
  return service({url: url, method: "post", data: params});
}
