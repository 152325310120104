<template>
  <div class="wrapper">
<!--     :style="headerStyle"-->
    <parallax class="page-header header-filter">
<!--      <div class="placeholder-img"><img :src="image"></div>-->
      <div class="md-layout">
        <div class="md-layout-item">
          <div class="image-wrapper">
            <div class="dictum-words">
                <h3>将者，</h3>
                <br><br>
                <h3>智、信、仁、勇、严也</h3>
            </div>
          </div>
        </div>
      </div>
    </parallax>
  </div>
</template>

<script>

export default {
  components: {
  },
  name: "index",
  bodyClass: "index-page",
  props: {
    image: {
      type: String,
      default: require("@/assets/img/svg/undraw_goals.svg")
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.image})`,
        backgroundPosition: 'right bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '50%'
      };
    },
  },
  mounted() {
  },
  beforeDestroy() {
  }
};
</script>
<style lang="scss">

@media all and (min-width: 991px) {
  .btn-container {
    display: flex;
  }
}
.placeholder-img{
  height: auto;
  position: absolute;
  right: -200px;
  bottom: -50%;
  img{
    width: 100%;
    height: 100%;
  }
}
</style>
