<template>
  <div class="wrapper">
    <parallax class="section page-header header-filter">
      <div class="container">
        <div class="md-layout">
          <div
              class="md-layout-item md-size-66 md-xsmall-size-100 mx-auto text-center"
          >
            <h1 class="text-center">GQRCode 二维码</h1>
            <h5 class="description">
              使用
              <a href="https://github.com/KangSpace/gqrcode" target="_blank">
                <i class="fab fa-github"></i>
                gqrcode
              </a>
              快速生成微型二维码和常规二维码。
            </h5>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main section main-content">
      <!--      <md-divider></md-divider>-->

      <div class="md-layout md-alignment-center qrcode-container container">
        <md-content class="md-layout-item md-medium-size-33 md-small-size-50 md-xsmall-size-100 qrcode-text-container qrcode-setting">
          <md-tabs @md-changed="tabChange">
            <md-tab id="qrcode-txt" md-label="文本">
              <md-field class="text-pane-container">
                <label>输入任意文本生成二维码</label>
                <md-textarea v-model="textContent" class="text-content-textarea" :md-counter="textCounter"
                             :maxlength="textCounter" spellcheck="false"></md-textarea>
                <span class="md-helper-text">* 文本长度保持在500字符内</span>
              </md-field>
            </md-tab>

            <md-tab id="qrcode-link" md-label="网址">
              <div class="description">
                <p>
                  网址直接生成二维码
                </p>
              </div>
              <div class="url-content-input-container ">
                <md-field :class="{'md-invalid':valid.urlInvalid}">
                  <label>请输入网址</label>
                  <md-input v-model="urlContent" :md-counter="textCounter" :maxlength="textCounter" spellcheck="false"
                            datatype="Url" class="input-group-outline"></md-input>
                  <span class="md-helper-text ">* 文本长度保持在500字符内</span>
                  <span class="md-error padding-top-10">请输入正确的网址</span>
                </md-field>
              </div>
<!--              <div>
                <md-button class="md-primary margin-top-10" style="float: left" @click="generateUrl">生成二维码
                </md-button>
                <div class="md-layout-item qrcode-size-select">
                  <md-field style="width: 120px;">
                    <label for="qrcodeSize">尺寸</label>
                    <md-select v-model="qrcodeSize" name="qrcodeSize" id="qrcodeSize">
                      <md-option v-for="(item) in qrcodeSizeList" :key="item" :value="item">
                        {{
                          item == qrcodeSizeList[0] ? item : (item + "x" + item)

                        }}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
              </div>-->
            </md-tab>
          </md-tabs>

          <div class="setting">
            <md-subheader>选项</md-subheader>
            <div class="md-layout-item qrcode-setting-field">
              <md-field >
                <label for="qrcodeSize">尺寸</label>
                <md-select v-model="qrcodeSize" name="qrcodeSize" id="qrcodeSize">
                  <md-option v-for="(item) in qrcodeSizeList" :key="item" :value="item">
                    {{
                      item === qrcodeSizeList[0] ? item : (item + "x" + item)
                    }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
<!--            <div class="md-layout-item qrcode-setting-field">
              <md-field>
                <label for="qrcodeSize">外边距</label>
                <md-input v-model="qrcodeBorderMargin" type="number" name="qrcodeBorderMargin" id="qrcodeBorderMargin"></md-input>
              </md-field>
            </div>-->
          </div>
          <md-button class="md-primary" @click="generateQrCode">生成二维码</md-button>
          <!--            <tabs
                        :tab-name="['文本', '网址']"
                        plain
                        color-button="primary"
                    >
                      <template slot="tab-pane-1">
                        <div class="description">
                          <p>
                            输入任意文本生成二维码
                          </p>
                        </div>
                        <md-field class="text-pane-container">
                          <label>请输入文本内容</label>
                          <md-textarea v-model="textContent" class="text-content-textarea" :md-counter="textCounter"
                                       :maxlength="textCounter" spellcheck="false"></md-textarea>
                          <span class="md-helper-text">* 文本长度保持在500字符内</span>
                        </md-field>
                        <div>
                          <md-button class="md-primary" @click="generateText">生成二维码</md-button>
                          <div class="md-layout-item qrcode-size-select" >
                          <md-field style="width: 120px;">
                            <label for="qrcodeSize">尺寸</label>
                            <md-select v-model="qrcodeSize" name="qrcodeSize" id="qrcodeSize" >
                              <md-option v-for="(item) in qrcodeSizeList"  :key="item"  :value="item">
                                {{
                                  item==qrcodeSizeList[0]?item:(item+"x"+item)

                                }}
                              </md-option>
                            </md-select>
                          </md-field>
                        </div>
                        </div>
                      </template>
                      <template slot="tab-pane-2">
                        <div class="description">
                          <p>
                            把网址直接生成二维码
                          </p>
                        </div>
                        <div class="url-content-input-container ">
                          <md-field :class="{'md-invalid':valid.urlInvalid}">
                            <md-icon>language</md-icon>
                            <label>请输入网址</label>
                            <md-input v-model="urlContent" :md-counter="textCounter" :maxlength="textCounter" spellcheck="false"
                                      datatype="Url" class="input-group-outline"></md-input>
                            <span class="md-helper-text ">* 文本长度保持在500字符内</span>
                            <span class="md-error padding-top-10">请输入正确的网址</span>
                          </md-field>
                        </div>
                        <div >
                          <md-button class="md-primary margin-top-10" style="float: left" @click="generateUrl">生成二维码</md-button>
                          <div class="md-layout-item qrcode-size-select" >
                            <md-field style="width: 120px;">
                              <label for="qrcodeSize">尺寸</label>
                              <md-select v-model="qrcodeSize" name="qrcodeSize" id="qrcodeSize" >
                                <md-option v-for="(item) in qrcodeSizeList"  :key="item"  :value="item">
                                  {{
                                  item==qrcodeSizeList[0]?item:(item+"x"+item)

                                  }}
                                </md-option>
                              </md-select>
                            </md-field>
                          </div>
                        </div>
                      </template>
                    </tabs>-->
        </md-content>
        <md-content class="md-layout-item md-medium-size-33 md-small-size-50 md-xsmall-size-100 qrcode-preview">
          <md-card>
            <md-card-content>
              <md-card-media>
                <div class="image-view">
                  <img :src="qrCodeView.url" v-show="qrCodeView.url"
                       alt="二维码预览"
                       class="img-raised rounded">
                  <span class="tip" v-show="!qrCodeView.url">此处预览二维码</span>
                </div>
              </md-card-media>
            </md-card-content>
            <md-card-actions>
              <md-button class="md-primary" :disabled="!qrCodeView.url" @click="downloadQR(qrCodeView.url)">下载
              </md-button>
            </md-card-actions>
          </md-card>
        </md-content>
        <!--          <div class="md-layout">
                    <md-content class="qrcode-img-container">
                         <div class="qrcode-img-card">
                           <md-card-content>
                             <md-empty-state v-if="qrCodeView.showEmpty"
                                             md-icon="qr_code_2"
                                             md-label=""
                                             md-description="预览二维码">
                             </md-empty-state>
                             <div class="md-layout-item ml-auto text-center" v-else>
                               <img
                                   :src="qrCodeView.url"
                                   alt="二维码预览"
                                   class="img-raised rounded"
                               />
                               <div class="md-layout-item md-xsmall-size-200 ml-auto text-center margin-top-10">
                                 <md-button class="md-button-clean md-dense" @click="downloadQR(qrCodeView.url)">下载二维码</md-button>
                               </div>
                             </div>
                           </md-card-content>
                         </div>
                       </md-content>
                  </div>-->
        <div id="nextQrcodeContainer" style="height: 10px;"></div>
      </div>
    </div>

    <md-snackbar :md-position="snackbar.position" :md-duration="snackbar.duration"
                 :md-active.sync="snackbar.showSnackbar" md-persistent>
      <span>{{ snackbar.message }}</span>
      <!--      <md-button class="md-primary" @click="showSnackbar = false">Retry</md-button>-->
    </md-snackbar>
  </div>
</template>

<script>
import config from "@/config/config";
import {requestPost} from "@/util/request";

export default {
  components: {
    // Tabs,
  },
  bodyClass: "gqrcode-page",
  props: {
  },
  data() {
    return {
      valid: {
        urlInvalid: false
      },
      snackbar: {
        showSnackbar: false,
        position: 'center',
        duration: 4000,
        message: "",
      },
      textCounter: 500,
      textContent: '',
      qrCodeType: 'qrcode-txt',
      urlContent: "",
      qrCodeView: {
        showEmpty: true,
        url: ""
      },
      qrcodeSize: 'Default',
      qrcodeBorderMargin: 0,
      qrcodeSizeList: [
        'Default',
        '100',
        '200',
        '400',
        '800'
      ],
    };
    M
  },
  computed: {
  },
  methods: {
    tabChange(activeTab){
      this.qrCodeType = activeTab;
    },
    isTxt() {
      return this.qrCodeType === 'qrcode-txt';
    },
    isLink() {
      return this.qrCodeType === 'qrcode-link';
    },
    getQRCodeSizeSelect() {
      return this.qrcodeSize !== this.qrcodeSizeList[0] ? this.qrcodeSize : 400;
    },
    showSnackBar(message) {
      this.snackbar.showSnackbar = true;
      this.snackbar.message = message;
    },
    refreshQrCodeView(url) {
      let $this = this;
      if (url) {
        this.qrCodeView.showEmpty = false;
        $this.qrCodeView.url = url;
        return;
      }
      this.qrCodeView.url = null;
      this.qrCodeView.showEmpty = true;
    },
    generateQrCode() {
      if (this.isTxt()) {
        this.generateText();
      }else if (this.isLink()){
        this.generateUrl();
      }
    },
    generateText() {
      let content = this.textContent;
      if (!content || !content.trim().length) {
        this.showSnackBar("请输入文本内容");
        return;
      }
      this.generateHandle(content);
    },
    generateUrl() {
      let content = this.urlContent;
      if (!content || !content.trim().length) {
        this.showSnackBar("请输入网址内容");
        return;
      }
      let lowerContent = content.toLowerCase();
      if (!lowerContent.startsWith("http://") && !lowerContent.startsWith("https://")) {
        this.showSnackBar("网址格式错误,请输入http或https的网址");
        return;
      }
      this.generateHandle(content);
    },
    generateHandle(content) {
      let host = config.siteConfig.host;
      let url = host + "/qrcode?_=" + (new Date().getTime());
      let data = "data=" + content + "&rt=json&s=" + this.getQRCodeSizeSelect();
      let $this = this;
      requestPost(url, data).then(function (res) {
        data = res.data
        if (data && data.code == 1) {
          $this.refreshQrCodeView($this.toQRCodeViewImgUrl(data.data.name, host));
        }
      }, function (x) {
        $this.showSnackBar("二维码生成失败,请重试~")
      });
    },
    toQRCodeViewImgUrl(data, host) {
      return host + "/static/" + data;
    },
    downloadQR: function (url, name) {
      if (!name) {
        name = url.substring(url.lastIndexOf("/"));
      }
      let aLink = document.createElement('a');
      // 下载图名字
      aLink.download = name;
      aLink.style.display = "none";
      aLink.href = url;
      aLink.target = "_blank";
      //url
      aLink.href = url;
      //合成函数，执行下载
      aLink.dispatchEvent(new MouseEvent('click'));
    },
  }
};
</script>


<style lang="scss">

.qrcode-setting .md-tabs-navigation {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  margin: 0 !important;
  border-radius: 0 !important;
  padding: 0 !important;
  overflow: auto !important;
}

.qrcode-setting .md-tabs.md-theme-default .md-tabs-navigation .md-button.md-active {
  color: #1b90ff !important;
}

.qrcode-setting .md-field.md-has-textarea:not(.md-autogrow):after, .md-field.md-has-textarea:not(.md-autogrow):before {
  height: auto;
  pointer-events: none;
  top: 0;
  bottom: 0;
  transform: none;
  background: 0 0 !important;
  border: 1px solid transparent;
  border-radius: 3px;
}

.qrcode-setting .md-field.md-has-textarea:not(.md-autogrow) label {
  top: 16px;
  left: 16px;
}

.qrcode-setting .md-field.md-has-textarea:not(.md-autogrow).md-focused label, .md-field.md-has-textarea:not(.md-autogrow).md-has-value label {
  top: 6px !important;
}

.qrcode-setting .md-field.md-has-textarea:not(.md-autogrow).md-focused .md-textarea, .md-field.md-has-textarea:not(.md-autogrow).md-has-value .md-textarea {
  padding-top: 10px !important;
}

.qrcode-setting .md-field.md-has-textarea:not(.md-autogrow) label {
  top: 16px;
  left: 16px;
}

.qrcode-setting .md-field.md-focused label, .qrcode-setting .md-field.md-has-value label {
  pointer-events: auto;
  top: 0;
  opacity: 1;
  font-size: 12px;
}

.qrcode-preview .md-card .md-card-actions div {
  display: flex;
}

</style>

<style lang="scss" scoped>

.page-header {
  height: 50vh;
}

.main-content {
  padding: 0 !important;
  margin-bottom: 70px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.qrcode-setting .setting{
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.qrcode-preview {
  //width: calc(100% - 60% - 20px);
  height: 100%;

  .image-view {
    max-height: 357px;
    max-width: 357px;
    min-height: 252px;
    min-width: 252px;
    margin: 15px auto;
    //border-radius: 6px;
    //border: 1px solid #d2d2d2;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;;
  }

  .image-view img {
    height: 100%;
    width: 100%;
    border-radius: 6px;
    border: 1px solid #d2d2d2;
  }

  .image-view .tip {
    color: rgba(0, 0, 0, 0.45) !important;
    user-select: none;
    font-size: 18px;
  }
}

.qrcode-container .md-field.md-has-textarea:not(.md-autogrow) .md-textarea {
  min-height: 258px !important;
}
</style>

<style lang="scss" scoped>
.md-card-actions.text-center {
  display: flex;
  justify-content: center !important;
}

.contact-form {
  margin-top: 30px;
}

.md-has-textarea + .md-layout {
  margin-top: 15px;
}

.md-tabs-navigation {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  margin: 0 !important;
  border-radius: 0 !important;
  padding: 0 !important;
  overflow: auto !important;
}


.qrcode-container {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, .12);
  //padding-top: 30px;
  padding: 30px;
  border-radius: 6px;

  -webkit-box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  overflow: auto;

  .qrcode-text-container {
    //width: 60%;
    //margin-right: 20px;
  }

}

.qrcode-setting-field {
  //min-width: 120px !important;
  display: inline-block !important;
  margin: 14px 10px 0 10px;
  width: auto;

  .md-field {
    margin: 0 !important;
  }
}

.md-list-item-text {
  position: relative !important;
}

</style>
