
const config = {
    siteConfig: {
        hostTitle: "KangSpace",
        siteKeyword: "KangSpace,KangSpace.org",
        siteDesc: "KangSpace,KangSpace.org",
        github: "https://github.com/KangSpace",
        host:"https://kangspace.org"
    }
};
export default config;