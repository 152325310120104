<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <md-divider class="footer-divider"></md-divider>
    <div class="container">
      <nav>
        <ul>
          <li>
            <a href="https://kangspace.org">
              KangSpace
            </a>
          </li>
          <li>
            <a href="https:///github.com/KangSpace">
              GITHUB
            </a>
          </li>
          <li>
            <a href="mailto:service@kangspace.org">
              联系我
            </a>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        &copy; {{ year }} 极谷世界
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style type="scss">
.footer-divider {
  background-color: #1777ff !important;
  height: 2px !important;
}

.container a, .copyright{
  font-size: 14px !important;
}
.container .copyright{
  color: #1777ff!important;
}
</style>
